import React from "react";
import dynamic from "next/dynamic";
import SectionBuilderController from "./SectionBuilder.controller";
const Footer = dynamic(() => import("../Footer/Footer.controller"));
import styles from "./PageBuilder.module.css";

const PageBuilderView = ({
  sections,
  rightStickySections,
  fixedSections,
  showFooter = true,
  pageWidth_px,
  pageGap_px,
  mainLayoutWidth,
  rightStickyLayoutWidth,
  spaceBetweenSections_px,
  pageMargin_px = 0,
  mainLayoutPadding_px = 0,
  mainLayoutBorder_px = 0,
  fixedSectionsBottom_px = 48,
  mainLayoutBg = false,
  showAfterSection = "",
  isPaidLead,
  isFixedVisible,
  fixedSectionRef,
  sectionsMap
}) => {
  return (
    <>
      <div
        className={styles["sections-wrapper"]}
        style={{
          "--page-width": `${pageWidth_px}px`,
          "--page-gap": `${pageGap_px}px`,
          "--main-layout-width": `${mainLayoutWidth}%`,
          "--right-sticky-layout-width": `${rightStickyLayoutWidth}%`,
          "--section-gap": `${spaceBetweenSections_px}px`,
          "--page-margin": `${pageMargin_px}px`,
          "--main-layout-padding": `${mainLayoutPadding_px}px`,
          "--main-layout-border": `${mainLayoutBorder_px}px`,
          ...(mainLayoutBg
            ? { "--main-layout-bg": "var(--bg-light-blue)" }
            : {}),
        }}
      >
        <div
          className={styles["sections"]}
          style={{
            flexBasis:
              rightStickySections.length > 0
                ? "var(--main-layout-width)"
                : "100%",
            boxSizing: "border-box",
          }}
        >
          <SectionBuilderController
            sections={sections}
            PageBuilderMap={sectionsMap}
          />
        </div>

        {rightStickySections.length > 0 && (
          <div
            className={styles["right-sticky-sections"]}
            style={{
              flexBasis: "var(--right-sticky-layout-width)",
              display: "block",
              boxSizing: "border-box",
            }}
          >
            <SectionBuilderController
              sections={rightStickySections}
              PageBuilderMap={sectionsMap}
            />
          </div>
        )}

        {fixedSections?.length > 0 && (
          <div
            ref={fixedSectionRef}
            className={styles.fixedSections}
            style={{
              position: "fixed",
              backgroundColor: "transparent",
              margin: "0",
              zIndex: 50,
              bottom: `${isPaidLead() ? "0" : fixedSectionsBottom_px}px`,
              display: isFixedVisible || !showAfterSection ? "block" : "none",
              right: 0,
              left: 0,
            }}
          >
            <SectionBuilderController
              sections={fixedSections}
              PageBuilderMap={sectionsMap}
            />
          </div>
        )}
      </div>

      {showFooter !== false ? <Footer /> : null}
    </>
  );
};

export default PageBuilderView;
