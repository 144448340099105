import { useEffect, useRef } from "react";

/**
 * useScrollListener hook
 * @param callback {Function} - The callback function to execute when the threshold is reached.
 * @param threshold {number} - The scroll position (in pixels) at which the callback should be triggered.
 * @param delta {number} - A delta value to provide some buffer around the threshold.
 * @param ref {React.RefObject} - A ref to the scrollable element.
 */
export const useScrollListener = (
  callback,
  threshold,
  delta = 0,
  ref,
  checkDirection = false
) => {
  const lastScrollYRef = useRef(0);
  const directionRef = useRef(0); // 0 for initial, 1 for down, -1 for up

  useEffect(() => {
    const uiScroller = ref?.current || window;
    const getScrollY = () =>
      uiScroller.pageYOffset || uiScroller.scrollTop || 0;

    const handleScroll = () => {
      const currentScrollY = getScrollY();
      let direction = currentScrollY > lastScrollYRef.current ? 1 : -1;

      if (
        !checkDirection &&
        currentScrollY > threshold - delta &&
        lastScrollYRef.current <= threshold - delta
      ) {
        callback(true, currentScrollY); // Scrolling down, and threshold is crossed
      } else if (
        !checkDirection &&
        currentScrollY <= threshold + delta &&
        lastScrollYRef.current > threshold + delta
      ) {
        callback(false, currentScrollY); // Scrolling up, and threshold is crossed
      }

      if (checkDirection) {
        callback(direction, currentScrollY);
      }

      lastScrollYRef.current = currentScrollY;
      directionRef.current = direction;
    };

    uiScroller.addEventListener("scroll", handleScroll, { passive: true });
    return () => uiScroller.removeEventListener("scroll", handleScroll);
  }, [callback, threshold, delta, ref]);
};
